import React from 'react'

import IndexLayout from '../../layouts';

import Footer from '../../components/Footer/Footer';
import SEO from '../../components/SEO/SEO';


import InscriptionHero from '../../components/Inscription/InscriptionHero/InscriptionHero';
import { LocaleTypes } from '../../data/locales';
import InscriptionPSP from '../../components/Inscription/InscriptionPSP/InscriptionPSP';
import { FormattedMessage } from 'react-intl';

interface pspProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const psp: React.FC<pspProps> = ({pathContext, location}) => {

  const content = () => (
    <>
      <SEO titleId="inscription.psp.title" descriptionId="inscription.psp.description" />
      <InscriptionHero>
        <h1>
          <FormattedMessage id="inscription.psp.title" />
        </h1>
        <p>
          <FormattedMessage id="inscription.psp.description" />
        </p>
      </InscriptionHero>

      <InscriptionPSP />

      <Footer theme="primary" />
    </>
  )
  return (
   <IndexLayout 
      render={ content }
      locale={pathContext.localeCode}
      navbarTheme="light"
      location={location}
    /> 
  )
}

export default psp